<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'days'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :selectFields=selectFields
    >
        <template #columns>
            <Column field="days" :header="$tc('time.day', 2)"></Column>
        </template>

        <template #form>
            <PaymentTermForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import PaymentTermForm from '@/components/paymentterm/PaymentTermForm.vue';
import paymentTermService from '@/services/PaymentTermService';

export default {
    components: {
        Overview,
        Column,
        PaymentTermForm
    },
    data() {
        return {
            item: {},
            itemService: paymentTermService,
            itemFormRef: null,
            selectFields: [
                "id",
                "days"
            ]
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        
    }
}
</script>