<template v-if="formActive">
    <FormItem id="days" :label="$tc('time.day', 2)" type="number" :allowCommas="false" v-model="item.days" :required="true" />
</template>

<script>
    export default {
        name: 'PaymentTermForm',
        props: ['itemData'],
        data() {
            return {
                item: { days: null },
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.item = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let valid = true;
                let required = document.querySelectorAll('#formModal input[required],#formModal select[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(valid) valid = this.item.days;
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.item;
            },
            setData(data) {
                this.item = data
            },
            reset() {
                this.item = { days: null }
                this.loading = false
                this.invalid = {}
            }
            
        }
    }
</script>
